<template>
  <div class="toast-container">
    {{ toastMessage }}
  </div>
</template>

<script>
export default {
  name: "Toast",
  data() {
    return { toastMessage: "", duration: 2000 };
  },
  components: {},
  methods: {
    show() {
      document.body.appendChild(this.$el);
    },
    hide() {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    },
  },
  created() {},
  mounted() {
    this.show();
    setTimeout(() => {
      this.hide();
    }, this.duration);
  },
};
</script>

<style lang="less" scoped>
.toast-container {
  position: fixed;
  left: 50%;
  top: 50%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  max-width: 40%;
  word-break: break-all;
  text-align: center;
  box-sizing: border-box;
  text-align: center;
  font-size: 26px;
}
</style>
