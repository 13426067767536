<template>
  <div v-if="evaluateVisible" class="satisfaction">
    <div class="invitation-to-evaluate-wrapper">
      <div class="invitation-to-evaluate">
        <div @click="close" class="evaluate-close-button">
          <i class="el-icon-close" />
        </div>
        <div class="evaluate-title">请您对本次服务进行评价</div>
        <div class="evaluate-content">
          <div class="option">
            <div class="option-title">评价：</div>
            <div class="options radio-satisfaction">
              <label
                v-for="item in evaluationList"
                :key="item.id"
                :for="`satisfaction-${item.dictLabel}`"
              >
                <input
                  @click="change(item)"
                  type="radio"
                  name="satisfaction"
                  :id="`satisfaction-${item.dictLabel}`"
                  :value="item.dictLabel"
                  v-model="currentValue"
                />{{ item.dictLabel }}
              </label>
            </div>
          </div>
          <div
            class="option"
            v-if="
              currentSelection &&
              currentSelection.childSatisfactionDictVO.length > 0
            "
          >
            <div class="option-title">意见：</div>
            <div class="options radio-satisfaction">
              <label
                v-for="item in currentSelection.childSatisfactionDictVO"
                :key="item.id"
                :for="`satisfaction-${item.id}`"
              >
                <input
                  @click="changeChild(item)"
                  type="radio"
                  name="satisfactionChild"
                  :id="`satisfaction-${item.id}`"
                  :value="item.dictLabel"
                  v-model="currentValueChild"
                />{{ item.dictLabel }}
              </label>
            </div>
          </div>
          <div
            class="option"
            v-if="
              currentSelection &&
              currentSelection.dictType === 'kf_bad_satisfaction_type'
            "
          >
            <div class="option-item">
              <textarea
                cols="30"
                rows="6"
                placeholder="请具体说明您的意见（10字以上）！"
                maxlength="60"
                v-model="satisComment"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="evaluate-submit">
          <button @click="submit">提交评价</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  satisfactionType,
  satisfactionHistory,
  satisfactionSubmit,
} from "@/api";
import Toast from "@/components/Toast";

export default {
  name: "Satisfaction",
  data() {
    return {
      evaluationList: [],
      evaluateVisible: false,
      currentSelection: null,
      currentSelectionChild: null,
      userStatus: {},
      satisComment: "",
      satisfactionHistoryInfo: null,
      invitationFlag: false,
      currentValue: undefined,
      currentValueChild: undefined,
    };
  },
  components: {},
  created() {},
  methods: {
    Toast,
    change(item) {
      this.currentSelection = item;
      this.currentSelectionChild = null;
    },
    changeChild(item) {
      this.currentSelectionChild = item;
    },
    async open(userStatus, invitationFlag) {
      this.userStatus = userStatus;
      this.invitationFlag = invitationFlag;
      // console.log(this.userStatus);
      await this.getSatisfactionType(this.userStatus.orgi, {
        tenantId: sessionStorage.getItem("tenantId"),
      });
      await this.getSatisfactionHistory(userStatus);
      if (this.satisfactionHistoryInfo) {
        this.currentValue = this.satisfactionHistoryInfo.satisType;
        this.currentSelection = this.searchSatisType(
          this.satisfactionHistoryInfo.satisType
        );
        this.satisComment = this.satisfactionHistoryInfo.satisComment;
      }
      this.evaluateVisible = true;
    },
    close() {
      this.evaluateVisible = false;
    },
    searchSatisType(type) {
      return this.evaluationList.find((item) => {
        return item.dictLabel === type;
      });
    },
    submit() {
      // console.log(this.currentSelection, this.currentSelectionChild);
      if (
        (this.evaluationList &&
          this.evaluationList.length > 0 &&
          !this.currentSelection) ||
        (this.currentSelection.childSatisfactionDictVO.length > 0 &&
          !this.currentSelectionChild)
      ) {
        this.Toast("请选择评价选项！");
        return;
      }
      if (
        this.currentSelection &&
        this.currentSelection.dictType === "kf_bad_satisfaction_type" &&
        this.satisComment.length < 10
      ) {
        this.Toast("评论内容请输入10字以上！");
        return;
      }
      const data = {
        id: this.satisfactionHistoryInfo?.id,
        goodReviewFlag:
          this.currentSelection.dictType === "kf_good_satisfaction_type",
        invitationFlag: this.invitationFlag,
        orgi: this.userStatus.orgi,
        satisComment: this.satisComment,
        satisType:
          this.currentSelectionChild?.dictLabel ||
          this.currentSelection?.dictLabel,
        visitorId: this.userStatus.visitorId,
      };
      // console.log(JSON.parse(JSON.stringify(data)));
      satisfactionSubmit(data, {
        tenantId: sessionStorage.getItem("tenantId"),
      }).then((res) => {
        this.Toast(res.data.msg);
        if (res.data.code === 0) {
          this.close();
        }
      });
    },
    getSatisfactionType(path, headers) {
      return new Promise((resolve, reject) => {
        satisfactionType(path, headers)
          .then((res) => {
            this.evaluationList = res.data.data;
            if (this.evaluationList.length > 0) {
              this.currentValue = this.evaluationList[0].dictLabel;
              this.currentSelection = this.evaluationList[0];
              if (this.currentSelection.childSatisfactionDictVO.length > 0) {
                this.currentValueChild =
                  this.currentSelection.childSatisfactionDictVO[0].dictLabel;
              }
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getSatisfactionHistory(userStatus) {
      return new Promise((resolve, reject) => {
        const { visitorId } = userStatus;
        satisfactionHistory(
          { visitorId },
          { tenantId: sessionStorage.getItem("tenantId") }
        )
          .then((res) => {
            if (res.data.code === 0 && res.data.data) {
              this.satisfactionHistoryInfo = res.data.data;
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.satisfaction {
  position: fixed;
  // display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  // align-items: center;
  font-size: 24px;

  .invitation-to-evaluate-wrapper {
    width: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .invitation-to-evaluate {
      max-height: 80%;
      overflow-y: scroll;
      width: 60%;
      padding: 30px;
      border-radius: 4px;
      background-color: #fff;
      position: relative;

      .evaluate-close-button {
        position: absolute;
        font-size: 30px;
        right: 30px;
        top: 30px;
        color: #b5b5b5;
      }

      .evaluate-title {
        text-align: center;
        padding: 16px 0;
      }

      .evaluate-content {
        .option {
          padding: 10px 0;

          .option-title {
            padding: 10px 0;
          }

          .options {
            // "kf_bad_satisfaction_type"
            label {
              padding: 10px 0;
              display: block;
              display: flex;
              align-items: center;

              input {
                margin: 0;
                margin-right: 20px;
              }
            }
          }

          .option-item {
            textarea {
              margin-top: 20px;
              box-sizing: border-box;
              width: 100%;
              resize: none;
              border-color: #e5e5e5;
              line-height: 1.6;
            }
          }
        }
      }

      .evaluate-submit {
        display: flex;
        justify-content: center;
        padding-top: 20px;

        button {
          width: 50%;
          padding: 6px;
          border-radius: 4px;
          border: none;
          background-color: #3d94f6;
          color: #fff;
        }
      }
    }
  }
}
</style>
