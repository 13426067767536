import request from "./request";
// 注册会话
export function getAppInfo(params) {
  return request({
    url: `/im/system/api/im/app/info`,
    method: "get",
    params,
  });
}
// 注册会话
export function registerVisitor(params, headers = {}) {
  return request({
    // url: `/kefu/api/im/init/setting`,
    url: `/im/system/api/im/init/setting`,
    method: "get",
    params,
    headers,
  });
}
// 文件上传
export function upload(data, headers = {}) {
  return request({
    url: `/im/system/api/im/upload`,
    method: "post",
    data,
    headers,
  });
}
// 获取历史消息记录
export function getHistoryMessageList(data, headers = {}) {
  return request({
    url: `/im/system/api/im/chatMessage`,
    method: "post",
    data,
    headers,
  });
}
// socketUrl
export function socketUrl() {
  return process.env.VUE_APP_SOCKET_URL;
}
// 获取评价选项
export function satisfactionType(path, headers = {}) {
  return request({
    url: `/im/system/api/im/satisfactionType`,
    method: "get",
    headers,
  });
}
// 获取评价记录
export function satisfactionHistory(params, headers = {}) {
  return request({
    url: `/im/system/api/im/satisfaction`,
    method: "get",
    params,
    headers,
  });
}
// 提交评价
export function satisfactionSubmit(data, headers = {}) {
  return request({
    url: `/im/system/api/im/satisfaction`,
    method: "post",
    data,
    headers,
  });
}
