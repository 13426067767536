<template>
  <!-- 问题分类 -->
  <div class="question-classification">
    <div class="question-classification-header">
      <div class="tips">请选择你要处理的问题大类</div>
      <div class="tips">我们将为你安排最贴心的人工客服</div>
    </div>
    <div class="question-classification-content">
      <ul class="question-type-list">
        <li
          v-for="item in questionData"
          :key="item.id"
          @click.stop="
            selectQuestionClassification(item.id, item.classificationName)
          "
          :class="{ selected: item.id === selectedQuestionClassification }"
        >
          <div class="question-type-item">
            <img
              :src="item.classificationLogo"
              :alt="item.classificationName"
            />
            <div>{{ item.classificationName }}</div>
          </div>
          <div class="selected-border">
            <div class="selected-icon-wrapper">
              <div class="selected-triangle"></div>
              <div class="selected-right"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questionData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectedQuestionClassification: "",
    };
  },
  methods: {
    selectQuestionClassification(key, questionClassification) {
      if (this.selectedQuestionClassification) return;
      this.selectedQuestionClassification = key;
      this.$emit("visitorChoiceQuestionClassification", {
        questionClassification,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.question-classification {
  background-color: #ffffff;
  border-radius: 9px;
  .question-classification-header {
    padding: 40px 30px;
    background-image: url("~@/assets/images/question-header-bg.png");
    background-size: auto 100%;
    background-position: right center;
    background-repeat: no-repeat;
    .tips {
      font-size: 30px;
      font-weight: 500;
      color: #333;
    }
  }
  .question-classification-content {
    ul.question-type-list {
      list-style: none;
      padding: 30px 20px 10px;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 33.33%;
        padding: 0 10px;
        box-sizing: border-box;
        position: relative;
        &.selected {
          .selected-border {
            position: absolute;
            box-sizing: border-box;
            left: 10px;
            right: 10px;
            top: 0;
            bottom: 20px;
            border: 4px #3497fd solid;
            border-radius: 10px;
            .selected-icon-wrapper {
              position: absolute;
              width: 56px;
              height: 56px;
              right: 0;
              bottom: 0;
              .selected-triangle {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-width: 0 0 56px 56px;
                border-color: transparent transparent #3497fd transparent;
                border-style: solid;
              }
              .selected-right {
                position: absolute;
                top: 26px;
                left: 26px;
                width: 28px;
                height: 28px;
                background-image: url("~@/assets/images/selected.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
              }
            }
          }
        }
        .question-type-item {
          margin-bottom: 20px;
          width: 100%;
          padding: 26px 0 30px;
          background-color: #edf3f7;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 64px;
            height: 64px;
            border-radius: 20px;
          }
          div {
            margin-top: 16px;
            font-size: 30px;
            font-weight: 500;
            line-height: 1;
            color: #323232;
          }
        }
      }
    }
  }
}
</style>
