import Vue from "vue";
import ElImageViewer from "./ImageViewer";

let ImageViewer = Vue.extend(ElImageViewer);
let imgPreviewArray = [];

const Viewer = function (event) {
  if (event.target.tagName !== "IMG") return;

  const currentTarget = event.currentTarget;
  const currentSrc = event.target.currentSrc;

  imgPreviewArray = Viewer.searchImgTags(currentTarget);

  const IV = new ImageViewer({
    data: {
      imgPreview: imgPreviewArray,
      imageIndex: Viewer.imageIndex(currentSrc),
    },
  });

  IV.previewImg(currentTarget, currentSrc);
  IV.$mount();
};

// 递归搜索img标签，获取src，返回找到src的Array
Viewer.searchImgTags = function (el) {
  const nodes = [];
  if (el.childNodes.length > 0) {
    el.childNodes.forEach((item) => {
      if (item.tagName === "IMG") {
        nodes.push(item.currentSrc);
      } else if (item.childNodes.length > 0) {
        nodes.push(...Viewer.searchImgTags(item));
      }
    });
  }
  return nodes;
};

Viewer.imageIndex = function (item) {
  let previewIndex = 0;
  console.log(imgPreviewArray);
  const srcIndex = imgPreviewArray.indexOf(item);
  if (srcIndex >= 0) {
    previewIndex = srcIndex;
  }
  return previewIndex;
};

export default Viewer;
