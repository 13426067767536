import Vue from "vue";
import ElToast from "./Toast";

const UIToast = Vue.extend(ElToast);

const Toast = function (toastMessage, duration = 2000) {
  const ToastInstance = new UIToast({
    data: {
      toastMessage,
      duration,
    },
  });
  ToastInstance.$mount();
};

export default Toast;
