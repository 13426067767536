import { emojiFilter } from "@/utils/emojiFilter";

const AgentNickName = "人工客服";

const displayData = function (data) {
  return data ? JSON.parse(JSON.stringify(data)) : "";
};

const output = function (data) {
  // console.log(data);
  this.chatMessageList.push(JSON.parse(JSON.stringify(data)));
  this.$nextTick(() => {
    this.scrollToBottom();
  });
};

const formatColor = ["color:#f08d49;"];
const socketLog = function (event, data) {
  const log = true;
  if (log) {
    console.log(`%c${event}: \t%o`, ...formatColor, displayData(data));
  }
};

const eventRegister = function (socket) {
  socket
    .on("connect", (data) => {
      // 连接成功
      socketLog("Connect", data);
      this.disconnected = false;
    })
    .on("message", (data) => {
      // 接收消息
      socketLog("Message", data);
      if (data.messageType === "text") {
        // console.log(data.message);
        data.message = emojiFilter(data.message);
        const tagReg = /<p.*?\/?>/g;
        if (!tagReg.test(data.message)) {
          data.message = `<p>${data.message}</p>`;
        }
      }
      if (data.callType === "呼入") {
        // console.log("呼入", data);
        data.position = "right";
      } else if (data.callType === "呼出") {
        // console.log("呼出", data);
        data.position = "left";
        data.senderNickname = AgentNickName;
        this.audioplayer("audioplane", "message", false);
      } else if (data.callType === "系统") {
        // console.log("系统", data);
        data.position = "center";
      }
      // else if (data.messageType === "choice_question_classification") {
      //   console.log("问题选择", data);
      //   data.position = "question";
      // }
      output.call(this, data);
    })
    .on("choice_question_classification", (data) => {
      if (data.openQuestionClassification) data.position = "question";
      output.call(this, data);
    })
    .on("message_revocation", (data) => {
      // 消息撤回
      socketLog("Message_revocation", data);
      const idx = this.chatMessageList.findIndex((item) => {
        // return item.chatMessageId === data;
        return item.id === data;
      });
      this.chatMessageList.splice(idx, 1);
    })
    .on("agentstatus", (data) => {
      // 坐席状态
      socketLog("Agentstatus", data);
      data.position = "center";
      output.call(this, data);
    })
    .on("satisfaction", (data) => {
      // 邀请评价
      socketLog("Satisfaction", data);
      this.openSatisfaction(true);
    })
    .on("status", (data) => {
      // 状态？？
      socketLog("Status", data);
      if (data.agentserviceid) {
        this.agentserviceid = data.agentserviceid;
      }
      data.position = "center";
      output.call(this, data);
    })
    .on("disconnect", (data) => {
      // 断开连接
      socketLog("Disconnect", data);
      this.disconnected = true;
    })
    .on("history_message", (data) => {
      // console.log("🚀 ~ file: index.js:88 ~ .on ~ data", data);
      // 历史消息
      // socketLog("HistoryMessage", data);
      this.chatMessageList = [];
      data.historyMessagesVO.forEach((item) => {
        if (item.messageType === "text") {
          // console.log(item.message);
          item.message = emojiFilter(item.message);
          const tagReg = /<p.*?\/?>/g;
          if (!tagReg.test(item.message)) {
            item.message = `<p>${item.message}</p>`;
          }
        }
        if (item.callType === "呼入") {
          item.position = "right";
        } else if (item.callType === "呼出") {
          item.position = "left";
          item.senderNickname = AgentNickName;
          this.audioplayer("audioplane", "message", false);
        } else if (item.callType === "系统") {
          item.position = "center";
        }
        output.call(this, item);
      });
    });
};

export default eventRegister;
