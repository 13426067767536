<template>
  <h1 @click="test">Test</h1>
</template>

<script>
export default {
  mounted() {},
  methods: {
    test() {
      console.log(this.$socket.connected);
      this.$socket.emit("message", "message");
    },
  },
};
</script>

<style lang="stylus" scoped></style>
