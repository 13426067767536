<template>
  <div class="notice-modal" v-if="visible">
    <div class="notice-modal-wrapper">
      <div class="notice-modal-inner">
        <h3>您的客服链接已失效</h3>
        <h3>
          请点击<a href="https://kefu.vyiot.com/?appid=wx3efe6114e073e8d1">
            此处
          </a>
          跳转至新的客服链接
        </h3>
        <h3>感谢您的配合❤️</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeModal",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.notice-modal {
  position: fixed;
  // display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  // align-items: center;
  font-size: 24px;
  .notice-modal-wrapper {
    width: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .notice-modal-inner {
      max-height: 80%;
      width: 60%;
      padding: 30px;
      border-radius: 4px;
      background-color: #fff;
      position: relative;
    }
  }
}
</style>
