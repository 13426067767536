<template>
  <ElImageViewer
    :url-list="imgPreview"
    v-if="showViewer"
    :initial-index="imageIndex"
    :on-close="closeViewer"
  />
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
let prevOverflow = "";
export default {
  name: "ImageViewer",
  data() {
    return {
      showViewer: false,
      imgPreview: [],
      imageIndex: 0,
    };
  },
  components: { ElImageViewer },
  computed: {},
  methods: {
    previewImg() {
      this.clickHandler();
      this.openViewer();
    },
    openViewer() {
      this.showViewer = true;
    },
    closeViewer() {
      document.body.style.overflow = prevOverflow;
      this.showViewer = false;
    },
    clickHandler() {
      // prevent body scroll
      prevOverflow = document.body.style.overflow;
      document.body.style.overflow = "hidden";
      this.showViewer = true;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
