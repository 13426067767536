import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/common/iconfont/iconfont.css";
import "@/common/js/rem.js";
import "element-ui/lib/theme-chalk/index.css";
// import VueSocketIO from "vue-socket.io";
// import IO from "socket.io-client";
import moment from "moment";
// import axios from "axios";
import { Image } from "element-ui";

Vue.config.productionTip = false;
// let chatOptions = {};
Vue.use(Image);
// Vue.use(Radio);
// axios.post("http://192.168.100.12:8035/im/text/104eac").then((res) => {
//   console.log("post------------", res.data);
//   chatOptions = {
//     userid: res.data.userid || "",
//     orgi: res.data.orgi || "",
//     session: res.data.sessionid || "",
//     appid: res.data.appid || "",
//     osname: res.data.osname || "",
//     browser: res.data.browser || "",
//     skill: res.data.skill || "",
//     nickname: res.data.username || "",
//   };
//   Vue.use(
//     new VueSocketIO({
//       debug: true,
//       connection: IO(
//         `ws://` +
//           `192.168.100.12` +
//           `:8036/im/user?` +
//           `userid=${chatOptions.userid}&` +
//           `orgi=${chatOptions.orgi}&` +
//           `session=${chatOptions.sessionid}&` +
//           `appid=${chatOptions.appid}&` +
//           `osname=&` +
//           `browser=&` +
//           `skill=${chatOptions.skill}&` +
//           `nickname=`,
//         { transports: ["websocket", "polling"] }
//       ),
//     })
//   );
// });

// Vue.use(
//   new VueSocketIO({
//     debug: true,
//     connection: IO(
//       "ws://" +
//         "192.168.8.10" +
//         ":8036/im/user?userid=c88100a7474cd6f32876ecf173808a98&orgi=cskefu&session=26c4842d-3eed-4994-a89d-ada15827c44b&appid=104eac&osname=&browser=&skill=2c9e80867d65eb5c017d65f17ceb0019&nickname=",
//       { transports: ["websocket", "polling"] }
//     ),
//   })
// );
// const aa = {
//   appid: "104eac",
//   orgi: "cskefu",
//   client: "62cc2fe67863473daf293c269f9b39b4",
//   type: "text",
//   skill: "2c9e80867d65eb5c017d65f17ceb0019",
//   userid: "57a03687125b7ca66a2ccb207f3229fa",
//   sessionid: "f4792751-eac0-4182-9106-2f7e6f429aa8",
//   t: "1641549838544",
// };

// console.log(aa);

// console.log(chatOptions);
// Vue.prototype.$chatOptions = chatOptions;

Vue.prototype.$moment = moment;

// const vl =
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

// console.log("Vue", vl);
