<template>
  <div id="app">
    <div id="kfxt">
      <div class="kfxt-container">
        <!-- header -->
        <header class="kfxt-header">
          <div class="header-left">
            <div class="logo" v-if="appInfo.logo">
              <img :src="appInfo.logo" alt="logo" />
            </div>
            <div class="title">{{ appInfo.clientShowName || "在线客服" }}</div>
            <div
              :class="disconnected ? 'offline' : 'online'"
              class="online-icon"
            ></div>
          </div>
          <div class="header-right">
            <div
              v-if="appInfo.satisfactionFlag"
              @click="openSatisfaction(false)"
              class="icon-box"
            >
              <i class="iconfont" style="color: #fd6e23">&#xe601;</i>
            </div>
            <div @click="_handleMute" class="sound-button icon-box">
              <i v-show="!mute" class="iconfont">&#xeca6;</i>
              <i v-show="mute" class="iconfont">&#xe635;</i>
            </div>
            <div @click="minimize" class="minimized-button icon-box">
              <i class="iconfont">&#xe63c;</i>
            </div>
          </div>
        </header>
        <!-- 消息框 -->
        <div
          @click="closeAllOptionBox"
          ref="messageBox"
          class="message-container"
          @touchmove="onTouchmove"
          @mousemove="onMousemove"
        >
          <div class="date-box">
            {{ $moment(initTime).format("YYYY-MM-DD HH:mm:ss") }}
          </div>
          <div v-if="agentstatus" class="message-text-box">
            {{ agentstatus }}
          </div>
          <div
            @click="ImageViewer"
            v-for="item in chatMessageList"
            :key="item.chatMessageList"
          >
            <!-- 问题分类选择 -->
            <QuestionClassification
              v-if="item.position === 'question'"
              :questionData="appInfo.questionClassifications"
              @visitorChoiceQuestionClassification="
                onVisitorChoiceQuestionClassification
              "
            />
            <!-- 系统消息 -->
            <div v-else-if="item.position === 'center'" class="center">
              <div class="message-text-outer-box">
                <div class="message-text-box" v-html="item.message"></div>
              </div>
            </div>
            <!-- 对话消息 -->
            <div v-else :class="item.position" class="message-box">
              <div class="message-top-line">
                <div class="user-name">
                  {{
                    item.position === "left"
                      ? `${appInfo.agentName || item.senderNickname}`
                      : ``
                  }}
                </div>
                <div class="message-time">
                  {{
                    $moment(new Date(Number(item.timestamp))).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}
                </div>
              </div>
              <div class="message-bottom-line">
                <div class="avatar-container" v-if="item.position === 'left'">
                  <div class="avatar">
                    <img
                      :src="
                        appInfo.agentAvatar ||
                        require('@/assets/images/avatar-default.png')
                      "
                      alt=""
                    />
                    <!-- <img
                      v-else-if="item.position === 'right'"
                      :src="
                        item.headImgUrl ||
                        require('@/assets/images/avatar-default-visitor.png')
                      "
                      alt=""
                    /> -->
                  </div>
                </div>
                <div class="message-text-outer-box">
                  <div
                    class="message-text-box"
                    @click="
                      item.msgtype == 'image' ? showImage(item.url) : null
                    "
                    v-html="item.message"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <!-- v-if="appInfo.satisfactionFlag && appInfo.suspendTipsFlag" -->
          <div
            v-if="appInfo.satisfactionFlag && appInfo.suspendTipsFlag"
            class="satisfaction-box"
            @touchstart.prevent="onTouchstart"
            @mousedown.prevent="onMousedown"
            @touchend.prevent="onTouchend"
            @mouseup.prevent="onMouseup"
            :style="{ bottom: `${satisfactionPosition.bottom}px` }"
          >
            <img :src="require('@/assets/images/satisfaction.png')" alt="" />
          </div>
        </div>
        <!-- 底部输入框+按钮 -->
        <footer class="input-container">
          <div class="operation-panel">
            <div @click="openOptions" class="more-option-button">
              <i class="iconfont">&#xe726;</i>
            </div>
            <!-- <div
              v-if="userStatus.satisfactionFlag"
              @click="openSatisfaction(false)"
              class="more-option-button"
            >
              <i class="iconfont" style="color: #fd6e23">&#xe601;</i>
            </div> -->
            <div class="input-box">
              <input
                type="text"
                v-model="message"
                placeholder="点击并输入您的问题"
                @click="closeAllOptionBox"
                @keydown.enter="sendMessage"
                ref="inputBox"
                :readonly="readonly"
                :disabled="disconnected"
              />
            </div>
            <div @click="openEmoticons" class="emoticon-button">
              <i class="iconfont">&#xe60c;</i>
            </div>
            <div class="submit-button">
              <button
                @click="sendMessage"
                :disabled="!message.length || disconnected || uploading"
              >
                <i v-show="uploading" class="el-icon-loading"></i> 发送
              </button>
            </div>
          </div>
          <form v-show="moreOptions" class="more-options">
            <label for="file-image" class="option-box file-image">
              <input
                style="display: none"
                type="file"
                id="file-image"
                ref="file-image"
                @change="sendImage"
                accept="image/*"
              />
              <div class="option"><i class="iconfont">&#xe6b2;</i></div>
            </label>
            <!-- <div
              v-if="userStatus.satisfactionFlag"
              @click="openSatisfaction(false)"
              class="option-box"
            >
              <div class="option"><i class="iconfont">&#xe601;</i></div>
            </div> -->
          </form>
          <div v-show="emojis" class="emojis">
            <div class="emojis-list">
              <div
                class="emoji"
                v-for="(em, index) in emojiList.split('|')"
                :key="index"
                @click="insertEmotion(`[${em}]`)"
              >
                <img :src="`/images/emoji/${em}.png`" :alt="`[emoji-${em}]`" />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <Satisfaction ref="Satisfaction" />
    <NoticeModal ref="NoticeModal" />
  </div>
</template>

<script>
import ImageViewer from "@/components/ImageViewer";
import Toast from "@/components/Toast";
import fp from "@fingerprintjs/fingerprintjs";
import eventRegister from "@/sockets/index";
import {
  getAppInfo,
  registerVisitor,
  // getHistoryMessageList,
  upload,
  socketUrl,
} from "@/api";
// import { emojiFilter } from "@/utils/emojiFilter";
import io from "socket.io-client";
import Satisfaction from "@/components/Satisfaction";
import NoticeModal from "@/components/NoticeModal";
import Compressor from "compressorjs";
import QuestionClassification from "./modules/QuestionClassification.vue";
const imgCompressor = function (
  file,
  maxWidth = 1920,
  maxHeight = 1920,
  quality = 0.8
) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      maxWidth,
      maxHeight,
      success: (result) => {
        // console.log(result);
        resolve(result);
      },
      error(err) {
        reject(err.message);
      },
    });
  });
};
export default {
  name: "Home",
  components: { Satisfaction, NoticeModal, QuestionClassification },
  data() {
    return {
      uploading: false,
      initTime: new Date(),
      emojiList:
        "微笑|撇嘴|发呆|得意|流泪|害羞|闭嘴|睡|大哭|调皮|呲牙|惊讶|冷汗|偷笑|愉快|流汗|憨笑|悠闲|奋斗|疑问|嘘|再见|擦汗|鼓掌|左哼哼|右哼哼|哈欠|委屈|快哭了|亲亲|可怜|笑脸|脸红|破涕为笑|嘿哈|捂脸|奸笑|机智|皱眉|耶|吃瓜|加油|汗|天啊|Emm|社会社会|好的|打脸|加油加油|哇|666|让我看看|爱心|拥抱|强|弱|握手|胜利|抱拳|勾引|拳头|OK|合十|玫瑰|月亮",
      mute: false,
      moreOptions: false,
      emojis: false,
      message: "",
      chatMessageList: [],
      agentstatus: "",
      readonly: false,
      noagent: false,
      $socket: null,
      disconnected: true,
      fp: "",
      userStatus: {},
      tenantId: "",
      appInfo: {},
      satisfactionPosition: {
        bottom: 200,
        onMouseDown: false,
        startBottom: 0,
        startPageY: 0,
        moved: false,
      },
      initHeight: 0,
    };
  },
  async mounted() {
    // console.log(this.$route.query);
    const queryKeys = Object.keys(this.$route.query);
    const query = {};

    if (queryKeys.includes("appid")) {
      query["appid"] = this.$route.query["appid"];
    }
    if (queryKeys.includes("appId")) {
      query["appid"] = this.$route.query["appId"];
    }
    if (queryKeys.includes("iccid")) {
      query["iccid"] = this.$route.query["iccid"];
    }
    if (queryKeys.includes("iccId")) {
      query["iccid"] = this.$route.query["iccId"];
    }
    if (queryKeys.includes("visitorid")) {
      query["visitorId"] = this.$route.query["visitorid"];
    }
    if (queryKeys.includes("visitorId")) {
      query["visitorId"] = this.$route.query["visitorId"];
    }
    if (queryKeys.includes("phone")) {
      query["phone"] = this.$route.query["phone"];
    }
    const appid = query.appid;
    const iccid = query.iccid;
    const phone = query.phone;
    const visitorId = query.visitorId;
    if (visitorId === "wx33deeefe0a4c8c12oqsfj5ik_aTayZnlU3dBl2kxj1Is") {
      this.$refs.NoticeModal.show();
      return;
    }

    const appInfo = await getAppInfo({ appId: appid });
    this.appInfo = appInfo?.data?.data;
    // console.log("🚀 ~ file: Home.vue:279 ~ mounted ~ appInfo", appInfo);
    this.tenantId = appInfo?.data?.data?.tenantId || "";
    sessionStorage.setItem("tenantId", this.tenantId);

    const params = {
      appid,
      phone: /^1[3-9]\d{9}$/.test(phone) ? phone : "",
      iccid,
    };
    if (visitorId) {
      params.clientId = visitorId;
    } else {
      const fp = await this.getFingerPrint(appid);
      params.clientId = fp;
    }
    const res = await registerVisitor(params, {
      tenantId: this.tenantId,
    });
    // console.log("🚀 ~ file: Home.vue:278 ~ mounted ~ res", res);
    if (res.data.code === 0) {
      // console.log(JSON.parse(JSON.stringify(res)));
      this.userStatus = res.data.data;
      // getHistoryMessageList(this.userStatus).then((res) => {
      //   console.log("getHistoryMessageList", res);
      //   const historyMessageList = res.data.data.map((item) => {
      //     const res = item;

      //     res.position = {
      //       系统: "center",
      //       呼入: "right",
      //       呼出: "left",
      //     }[item.callType];
      //     return res;
      //   });
      //   if (res.data.code === 200) {
      //     this.chatMessageList.unshift(
      //       ...historyMessageList.map((item) => {
      //         item.message = emojiFilter(item.message);
      //         return item;
      //       })
      //     );
      //   }
      //   this.$nextTick(() => {
      //     this.scrollToBottom();
      //   });
      // });
      const url = socketUrl();
      const socket = io(url, {
        transports: ["websocket", "polling"],
        timeout: 3000,
        query: res.data.data,
      });
      eventRegister.call(this, socket);
      this.$socket = socket;
    }
    this.initHeight = this.$refs.messageBox.clientHeight;
  },
  methods: {
    onVisitorChoiceQuestionClassification({ questionClassification }) {
      this.$socket.emit("visitorChoiceQuestionClassification", {
        visitorId: this.userStatus.visitorId,
        questionClassification,
      });
    },
    onTouchstart(e) {
      this.satisfactionPosition.onMouseDown = true;
      const { pageY } = e.touches[0];
      this.satisfactionPosition.startPageY = pageY;
      this.satisfactionPosition.moved = false;
      this.satisfactionPosition.startBottom = this.satisfactionPosition.bottom;
    },
    onMousedown(e) {
      this.satisfactionPosition.onMouseDown = true;
      const { pageY } = e;
      this.satisfactionPosition.startPageY = pageY;
      this.satisfactionPosition.moved = false;
      this.satisfactionPosition.startBottom = this.satisfactionPosition.bottom;
    },
    onTouchend() {
      this.satisfactionPosition.onMouseDown = false;
      if (!this.satisfactionPosition.moved) {
        this.openSatisfaction(false);
        this.satisfactionPosition.moved = false;
      }
    },
    onMouseup() {
      this.satisfactionPosition.onMouseDown = false;
      if (!this.satisfactionPosition.moved) {
        this.openSatisfaction(false);
        this.satisfactionPosition.moved = false;
      }
    },
    onTouchmove(e) {
      if (!this.satisfactionPosition.onMouseDown) return;
      this.satisfactionPosition.moved = true;
      const { pageY } = e.touches[0];
      const { startBottom, startPageY } = this.satisfactionPosition;
      const tempButton = startBottom - pageY + startPageY;
      if (tempButton < 60 || tempButton > this.initHeight - 10) return;
      this.satisfactionPosition.bottom = tempButton;
    },
    onMousemove(e) {
      if (!this.satisfactionPosition.onMouseDown) return;
      this.satisfactionPosition.moved = true;
      const { pageY } = e;
      const { startBottom, startPageY } = this.satisfactionPosition;
      const tempButton = startBottom - pageY + startPageY;
      if (tempButton < 0 || tempButton > this.initHeight - 10) return;
      this.satisfactionPosition.bottom = tempButton;
    },
    Toast,
    ImageViewer,
    openSatisfaction(invitationFlag) {
      this.$refs.Satisfaction.open(this.userStatus, invitationFlag);
    },
    closeSatisfaction() {
      this.$refs.Satisfaction.close();
    },
    insertEmotion(em) {
      // console.log("insertEmotion");
      if (this.disconnected) return;
      const input = this.$refs.inputBox;
      let wordinx = input.selectionStart;
      // console.log(em, em.toString().length);
      if (wordinx >= 0) {
        let text = this.message;
        let value =
          text.substring(0, wordinx) +
          em +
          text.substring(wordinx, text.length);
        this.message = value;
        setTimeout(() => {
          input.selectionStart = wordinx + em.toString().length;
          input.selectionEnd = wordinx + em.toString().length;
        }, 0);
      }
    },
    getRndId() {
      let rnd = "";
      if (localStorage) {
        rnd = localStorage.finger;
        if (!rnd) {
          rnd = Math.random();
          localStorage.finger = rnd;
        }
      }
      return rnd;
    },
    getFingerPrint(appid) {
      return new Promise((resolve, reject) => {
        const fpPromise = fp.load();
        fpPromise
          .then((fp) => fp.get())
          .then((result) => {
            const components = {
              ...result.components,
              appId: { value: appid },
              rndId: {
                value: this.getRndId(),
              },
            };
            const visitorId = fp.hashComponents(components);
            // console.log(visitorId);
            resolve(visitorId);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    showImage(url) {
      console.log(url);
    },
    async sendImage(e) {
      const source = e.target;
      let resultFiles = [];
      resultFiles.push(source.files[0]);
      if (resultFiles) {
        const data = new FormData();
        const fileResult = await imgCompressor(resultFiles[0]);
        data.append("file", fileResult, fileResult.name);
        data.append("nickname", "nickname");
        data.append("tenantId", this.userStatus.tenantId);
        data.append("code", 2);
        this.uploading = true;
        upload(data, { tenantId: this.tenantId })
          .then((res) => {
            if (res.data.code === 0) {
              this.$socket.emit("message", {
                message: `<p><img src="${res.data.data.url}" /></p>`,
                appId: "appId",
                clientId: this.userStatus.visitorId,
                senderId: this.userStatus.visitorId,
                orgi: this.userStatus.orgi,
                nickname: this.userStatus.nickname,
                type: "message",
                agentserviceid: this.agentserviceid,
                visitorId: this.userStatus.visitorId,
              });
            } else {
              this.Toast(res.data.msg);
            }
          })
          .finally(() => {
            this.uploading = false;
          });
      }
    },
    audioplayer(id, file, loop) {
      if (this.mute) return;
      if (typeof file == "undefined") return;
      let audioplayer = document.querySelector(`#${id}`);
      let mp3;
      if (!audioplayer) {
        // document.body.removeChild(audioplayer);
        audioplayer = document.createElement("audio");
        audioplayer.id = id;
        audioplayer.setAttribute("autoplay", "autoplay");
        if (loop) {
          audioplayer.setAttribute("loop", "loop");
        }
        document.body.appendChild(audioplayer);

        mp3 = document.createElement("source");
        mp3.type = "audio/mpeg";
        audioplayer.appendChild(mp3);
      } else {
        mp3 = audioplayer.getElementsByTagName("source")[0];
      }
      mp3.src = require(`@/assets/audio/${file}.mp3`);
      audioplayer.play();
    },
    sendMessage() {
      // console.log(typeof this.message);
      if (this.message.trim() === "") {
        this.Toast("内容不能为空！");
        return;
      }
      this.$socket.emit("message", {
        message: `<p>${this.message}</p>`,
        appId: "appId",
        clientId: this.userStatus.visitorId,
        senderId: this.userStatus.visitorId,
        orgi: this.userStatus.orgi,
        nickname: this.userStatus.nickname,
        type: "message",
        agentserviceid: this.agentserviceid,
        visitorId: this.userStatus.visitorId,
      });
      this.message = "";
      // this.$nextTick(() => {

      // })
    },
    scrollToBottom() {
      const container = this.$refs.messageBox;
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
    openOptions() {
      this.closeAllOptionBox();
      this.moreOptions = true;
    },
    closeOptions() {
      this.moreOptions = false;
    },
    openEmoticons() {
      this.closeAllOptionBox();
      this.emojis = true;
      this.$refs.inputBox.focus();
      // this.$refs.inputBox.blur();
    },
    closeEmoticons() {
      this.emojis = false;
    },
    closeAllOptionBox() {
      // console.log("closeAllOptionBox");
      this.closeEmoticons();
      this.closeOptions();
    },
    getEmotion(index) {
      return require(`@/assets/images/${index}.png`);
    },
    _handleMute() {
      this.mute = !this.mute;
    },
    minimize() {
      if (!window.ZMY_KF_parentSource) return;
      window.ZMY_KF_parentSource.postMessage(
        { type: "hideContainer" },
        window.ZMY_KF_parentOrigin
      );
    },
    closeChat() {
      // console.log("closeChat");
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  position: relative;
  // text-align: center;
  height: 100%;
  color: #2c3e50;
  max-width: 750px;
  margin: 0 auto;
  // margin-top: 60px;
  #kfxt {
    height: 100%;
    .kfxt-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      .kfxt-header {
        width: 100%;
        height: 104px;
        background: #3d94f6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        box-sizing: border-box;
        .header-left {
          display: flex;
          align-items: center;
          .logo {
            margin-right: 10px;
            img {
              width: 80px;
              height: 80px;
            }
          }
          .online {
            background: #3ce021;
          }
          .offline {
            background: #c20101;
          }
          .online-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 16px;
          }
          .title {
            margin-right: 16px;
            font-size: 34px;
            font-weight: bold;
            color: #ffffff;
          }
        }
        .header-right {
          display: flex;
          align-items: center;
          .icon-box {
            margin: 0 15px;
            .iconfont {
              cursor: pointer;
              font-size: 34px;
              color: #ffffff;
            }
          }
          .sound-button {
            .iconfont {
            }
          }
          .minimized-button {
            .iconfont {
            }
          }
          .close-button {
            .iconfont {
            }
          }
        }
      }
      .message-container {
        flex-basis: 0;
        flex-grow: 1;
        background-color: #f5f5f5;
        padding: 0 20px;
        overflow-y: scroll;
        position: relative;
        .satisfaction-box {
          position: fixed;
          right: 0;
          // bottom: 350px;
          img {
            width: 130px;
          }
        }
        .date-box {
          margin: 30px 0;
          font-size: 24px;
          font-weight: 500;
          color: #999999;
          text-align: center;
        }
        .tips-box {
          border-radius: 6px;
          font-size: 26px;
          font-weight: 500;
          color: #333333;
          background-color: #ffffff;
        }

        .message-text-box {
          word-break: break-all;
          margin: 0 20px;
          box-sizing: border-box;
          padding: 12px;
          border: 2px solid #f5f5f5;
          border-radius: 6px;
          font-size: 26px;
          line-height: 1.6;
          font-weight: 500;
          color: #333333;
          background-color: #ffffff;
        }
        .message-box {
          margin: 50px 0;
          .message-top-line {
            display: flex;
            padding: 17px 0;
            .user-name {
              max-width: 40%;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 24px;
              font-weight: 500;
              color: #666666;
            }
            .message-time {
              margin: 0 12px;
              font-size: 24px;
              font-weight: 500;
              color: #999999;
            }
          }
          .message-bottom-line {
            display: flex;
            justify-content: space-between;
            .message-text-outer-box {
              width: 620px;
              display: flex;
            }
            .avatar-container {
              .avatar {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #eee;
                // background-color: purple;
                img {
                  max-width: 100%;
                }
              }
            }
          }
        }
        .right {
          .message-top-line,
          .message-bottom-line {
            flex-direction: row-reverse;
            .message-text-outer-box {
              flex-direction: row-reverse;
              .message-text-box {
                max-width: 500px;
              }
            }
          }
        }
        .center {
          .message-text-outer-box {
            .message-text-box {
              width: 100%;
              background-color: transparent;
              color: #999;
              text-align: center;
            }
          }
        }

        .right {
          .message-top-line,
          .message-bottom-line {
            .message-text-outer-box {
              .message-text-box {
                max-width: 500px;
              }
            }
          }
        }
      }
      .input-container {
        .operation-panel {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 10px;
          background-color: #ffffff;
          .more-option-button {
            .iconfont {
            }
          }
          .more-option-button,
          .emoticon-button {
            margin: 0 10px;
            .iconfont {
              font-size: 44px;
            }
          }
          .emoticon-button {
            .iconfont {
            }
          }
          .input-box {
            flex-grow: 1;
            margin: 0 10px;
            input {
              box-sizing: border-box;
              width: 100%;
              padding: 20px;
              border: none;
              border-radius: 6px;
              font-size: 26px;
              font-weight: 500;
              line-height: 1;
              // color: #999999;
              background: #f7f7f7;
            }
          }
          .submit-button {
            margin: 0 10px;
            button {
              width: 120px;
              height: 54px;
              border: none;
              border-radius: 6px;
              font-size: 28px;
              font-weight: 500;
              color: #ffffff;
              background-color: #3d94f6;
              &:disabled {
                background-color: #ccc;
              }
            }
          }
        }
        .more-options {
          display: flex;
          background-color: #f2f2f2;
          padding: 40px 0;
          .option-box {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            .option {
              width: 88px;
              height: 88px;
              border-radius: 6px;
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              .iconfont {
                font-size: 44px;
              }
            }
          }
          .option-box {
            .option {
              .iconfont {
              }
            }
          }
        }
        .emojis {
          height: 265px;
          overflow-y: scroll;
          background-color: #f5f5f5;
          .emojis-list {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 16px;
            .emoji {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 8px 0;
              width: calc(100% / 10);
              img {
                width: 88%;
              }
            }
          }
        }
      }
    }
  }
  .toast-container {
    position: fixed;
    left: 50%;
    top: 50%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    max-width: 30%;
    word-break: break-all;
    text-align: center;
    box-sizing: border-box;
  }
}
</style>
<style lang="less">
.message-text-box {
  img[alt^="[emoji-"] {
    display: inline;
    width: 30px;
    vertical-align: middle;
  }
  img {
    max-width: 100%;
  }
  p {
    margin: 0;
  }
  blockquote {
    display: block;
    padding: 5px;
    margin: 10px 0;
    line-height: 1;
    // font-size: 100%;
    // background-color: #f1f1f1;
    background-color: rgba(0, 0, 0, 0.1);
    :first-child {
      display: inline-block;
      margin-bottom: 16px;
    }
    p {
      margin: 0;
    }
  }
}
</style>
