<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style lang="less">
body {
  background-color: #3d94f6;
}
</style>
